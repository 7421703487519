<template>
  <div class="account-container">
    <h3>Account Info</h3>

    <div class="flex-base-row profile-photo">
      <user-svg v-if="!currentUser.profile_photo && !currentUser.profile_photo_url" />
      <img :class="['profile-photo-preview', 'test', { 'hidden': !currentUser.profile_photo }]" ref="profile-photo-preview" src="#" />
      <img :class="['profile-photo-preview', { 'hidden': currentUser.profile_photo }]" v-if="currentUser.profile_photo_url && !currentUser.profile_photo" :src="currentUser.profile_photo_url" />

      <label for="profile_photo" class="upload-file-button hlt-button secondary">
          Change
      </label>

      <input
        id="profile_photo"
        ref="profile_photo"
        type="file"
        class="upload-file-field"
        name="profile_photo"
        accept="image/*"
        @change="handleFileUpload('profile_photo')"
      >

    </div>
    <div class="flex-base-row">
      <div class="flex-left">
        <label for="">First name</label>
        <input type="text" class="hlt-input first-name-input" v-model="currentUser.first_name">
      </div>
      <div class="flex-right">
        <label for="">Last name</label>
        <input type="text" class="hlt-input last-name-input" v-model="currentUser.last_name">
      </div>
    </div>
    <div class="flex-base-row">
      <div class="flex-left">
        <label for="">Email</label>
        <input type="text" class="hlt-input" v-model="currentUser.email" :disabled="true">
      </div>
      <div class="flex-right">
        <label for="">Phone Number</label>
        <input type="text" class="hlt-input phone-input" v-model="currentUser.phone">
      </div>
    </div>
    <div class="button-area">
      <button
        class="update-button hlt-button secondary"
        @click="updateUser"
      >
        Update
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import railsRoutes from '@/store/rails-routes'

export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ])
  },
  methods: {
    ...mapMutations([
      'setAssetType'
    ]),
    ...mapActions([
      'attachmentUpdate'
    ]),
    handleFileUpload (name) {
      this.$set(this.currentUser, name, this.$refs[name].files[0])
      this.$refs['profile-photo-preview'].src = URL.createObjectURL(this.$refs[name].files[0])
    },
    updateUser () {
      let url = railsRoutes.api_path({ type: 'admin_users' })
      this.attachmentUpdate({ fullUrl: url, asset: this.currentUser }).then(() => {
        this.$notify({
          title: 'Account update successful',
          type: 'success'
        })
      }).catch(error => {
        console.error(error.response)
        this.errors = error.response.data.errors
      })
    }
  },
  mounted () {
    this.setAssetType({ assetType: 'admin_user' })
  }
}
</script>

<style lang="scss" scoped>
.account-container {
  width: 80%;
  h3 {
    margin-bottom: 40px;
  }

  .profile-photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;

    .hidden {
      display: none;
    }
    .profile-photo-preview {
      max-width: 150px;
    }
    .upload-file-button {
      margin-top: 10px;
      font-size: 12px;
    }
    .upload-file-field {
      display: none;
    }
    svg {
      border: 1px solid $space-gray;
      padding: 10px;
      width: 100px;
      color: $space-gray;
      fill: $space-gray;
    }
  }
  .flex-base-row {
    input {
      margin-right: 30px;
    }
  }
  .button-area {
    display: flex;
    justify-content: right;
  }
}
</style>
