<template>
  <div>
    <nav-container>
      <template v-slot:headerName>
        <div class="title">
          Account
        </div>
      </template>
    </nav-container>

    <div class="account-container">
      <div class="account-view">
        <div class="account-tabs">
          <ul class="account-tabs-list">
            <li @click="changeThings('AccountInfo')">
              Account Info
            </li>
            <li @click="changeThings('PasswordUpdate')">
              Security
            </li>
          </ul>
        </div>
        <div class="account-view-content">
          <component :is="currentComponent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavContainer from './shared/NavContainer.vue'
import AccountInfo from './AccountInfo.vue'
import PasswordUpdate from './authentication/PasswordReset.vue'

export default {
  components: {
    AccountInfo,
    PasswordUpdate,
    NavContainer
  },
  data() {
    return {
      currentComponent: AccountInfo
    }
  },
  methods: {
    changeThings (tab) {
      this.currentComponent = tab
    }
  }
}
</script>

<style lang="scss" scoped>
.account-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .account-view {
  flex-grow: 1;
    display: flex;
    .account-tabs {
      flex: 1;
      border-right: 1px solid $space-gray-20;
      .account-tabs-list {
        flex: 1;
        list-style-type: none;
        margin: 0;
        li {
          padding: 20px;
          border-bottom: 1px solid $space-gray-20;
          cursor: pointer;
          &:hover {
            background: $space-gray-30;
          }
        }
      }
    }
    .account-view-content {
      padding: 20px;
      flex: 5;
      margin-left: 10px;
    }
  }
}
</style>
